<template>
  <li class="cart-nav onhover-dropdown">
    <div class="cart-box">
      <router-link :to="'/cart'">
        <vue-feather type="shopping-cart"></vue-feather>
        <span class="badge rounded-pill badge-success">1</span></router-link>
    </div>
  </li>
</template>

<script setup>
</script>
