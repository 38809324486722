<template>
  <Breadcrumbs title="ข้อตกลงการใช้บริการ (Terms of use)" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div >
                <div class="media-body">
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>ข้อตกลงการใช้บริการ และ นโยบายความเป็นส่วนตัว (Terms of Use & PDPA)</h4>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้ารับทราบและยินยอมในข้อตกลงการใช้งาน และอ่านทำความเข้าใจนโยบายของระบบ
เพื่อให้เป็นไปตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (Personal Data Protection Act: PDPA) 
และสอดคล้องกับ นโยบายการคุ้มครองข้อมูลส่วนบุคคล ของเว็บไซต์ runrace.in.th </p>
                  <b>วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</b>
                  <p>เพื่อให้สามารถทำตามวัตถุประสงค์ของผู้ใช้บริการ ในการใช้บริการต่างๆ และแจ้งข้อมูลไปยังผู้ใช้บริการ</p>
                  <b>การเก็บข้อมูลส่วนบุคคล</b>
                  <p>ระบบจัดเก็บข้อมูล การสั่งซื้อสินค้า และ/หรือ บริการ ของผู้ใช้บริการ</p>
                  <b>ระยะเวลาในการจัดเก็บข้อมูล</b>
                  <p>ระบบจะเก็บรักษาข้อมูลที่ได้รับจากผู้ใช้บริการเป็นอย่างดี ด้วยระบบรักษาความปลอดภัยที่ได้มาตรฐาน จนกว่าจะมีการแก้ไข เปลี่ยนแปลง หรือยกเลิกโดยผู้ใช้บริการ</p>
                    <p style="text-align: right;" >RunRace</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>