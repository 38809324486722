export var menuItems={
  "data": [
    {
      "headTitle1": "RUNRACE.IN.TH",
      "headTitle2": "ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH",
      "type": "headtitle"
    },
    
    
    {
      "path": "/",
      "title": "กิจกรรม",
      "title_en":"Event",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "type": "link",
      "active":false
    },
    {
      "path": "/find-order",
      "title": "ค้นหาใบสมัคร",
      "title_en":"Find order",
      "icon": "stroke-search",
      "iconf":"fill-search",
      "type": "link",
      "active":false
    },
    {
      "path": "/about",
      "title": "เกี่ยวกับเรา",
      "title_en":"About",
      "icon": "stroke-faq",
      "iconf":"fill-faq",
      "type": "link",
      "active":false
    }
  ]
}