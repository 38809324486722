<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <form @submit.prevent="findOrder">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <vue-feather class="m-r-30" type="search"></vue-feather>
                  <div class="media-body">
                    <h6 class="f-w-500"> </h6>
                    <div class="row">
                      <div class="mb-12 col-sm-12">
                        <label for="moblieNo"
                          ><h3>
                            ค้นหาใบสมัคร
                            
                          </h3>
                          <small class="form-text text-muted"
                            >ค้นหาใบสมัคร ด้วยหมายเลขโทรศัพท์</small
                          >
                        </label>
                        <input
                          required
                          maxlength="10"
                          minlength="10"
                          type="text"
                          class="form-control"
                          id="moblieNo"
                          placeholder="หมายเลขโทรศัพท์"
                          v-model="txt_search"
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> ค้นหา
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="row" v-if="this.start_search === 'true'">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="card">
            <div class="card-body">
              <div v-if="this.is_searching === 'true'">
                <div class="d-flex justify-content-center">
                  <img width="60" src="../../assets/images/running_loading.svg" />
                </div>
              </div>

              <div
                v-if="!this.resultList.length && this.is_searching === 'false'"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">วันที่สั่งซื้อ</th>
                      <th scope="col">สถานะ</th>
                      <th scope="col">ดูคำสั่งซื้อ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="3" style="text-align: center">
                        ไม่พบข้อมูลคำสั่งซื้อ
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="this.resultList.length">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">วันที่สมัคร</th>
                      <th scope="col">กิจกรรม</th>
                      <th scope="col">สถานะ</th>
                      <th scope="col">ใบสมัคร</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(src, index) in this.resultList" :key="index">
                      <td>{{ src.create_date }}</td>
                      <td>{{ src.title }}</td>
                      <td v-if="src.status === 'INITIAL'">
                        <font style="color: red;">{{this.currentLanguage ==='en'?"Waiting for payment":"รอชำระเงิน"}}</font>
                      </td>
                      <td v-if="src.status === 'PAID'">
                        <font style="color: green;">{{this.currentLanguage ==='en'?"Completed":"สำเร็จ"}}</font>
                      </td>
                      <td> <a target="_blank" :href=" '/order/' + src.order_id"><u><b>{{ src.order_id }}</b></u></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'
export default {
  data() {
    return {
      email: "",
      txt_search: "",
      resultList: {},
      status: "",
      is_searching: "false",
      start_search: "false",
      currentLanguage: 'th'
    };
  },
  setup() {
    useHead({
      title: computed(() => `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `'งานวิ่ง ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH'`),
        },
        {
          name: `keywords`,
          content: computed(() => `งานวิ่ง,ระบบรับสมัครงานวิ่ง,ค้นหาคำสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,runrace,runninghub,facesearch`),
        }
        ],
     
    })
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async findOrder() {

      if(this.txt_search === '' || this.txt_search === null){
        return
      }
      
      this.start_search = "true";
      this.resultList = {};
      this.is_searching = "true";

      
      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        txt_search: this.txt_search
      };
      let result = await axios.post("/v1/order/find-order", data);
      this.status = result.data.status;
      if (result.data.status === "success") {
        this.resultList = result.data.body;
      }
      this.is_searching = "false";
    },
    async clear_result() {
      this.start_search = "false";
      this.resultList = {};
      this.txt_search = "";
    },
  },
};
</script>