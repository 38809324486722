<template>
  <div class="col-xxl-12">
    <div class="row">
      <div class="card social-profile">
        <div class="card-body">
          <div class="social-img-wrap">
            <div class="social-img">
              <img src="@/assets/images/running/user-icon.png" alt="profile" />
            </div>
            <div class="edit-icon">
              <svg>
                <use href="@/assets/svg/icon-sprite.svg#profile-check"></use>
              </svg>
            </div>
          </div>
          <div class="social-details">
            <div class="row">
              <div class="d-flex justify-content-center">
                <h4 v-if="this.user">{{ this.user.fname }} {{ this.user.lname }}</h4>
              </div>
              <div class="d-flex justify-content-center">
                <b v-if="this.user"> {{ this.user.email }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "welcomecard",
  data() {
    return {
      user: {}
    };
  },
  async mounted() {
    try {
      this.user = JSON.parse(localStorage.getItem('_rr_user'))
      if (this.user === null || this.user === "") {
        this.$router.push({ path: "/auth/login" });
      }
    } catch (error) {
      this.$router.push({ path: "/auth/login" });
    }
  },

};
</script>
