<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <welcomecard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>บัญชีผู้ใช้งาน</b></h5>
                <button type="button" @click.prevent="load_my_order()" class="btn btn-primary">
                  <vue-feather type="save" size="18"> </vue-feather> บันทึก</button>
              </div>
            </div>
            <div class="d-flex justify-content-center">Hi,****</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import welcomecard from "./user_welcomecard.vue";
import axios from "axios";
export default {
  components: {
    welcomecard,
  },
  data() {
    return {
      token: null,
      is_load_my_order: "N",
      data_my_order: [],
      _rr_user: null
    }
  },
  async mounted() {
    this.token = localStorage.getItem("_rr_token");
    if(localStorage.getItem("_rr_user") != null){
      this._rr_user =  JSON.parse(localStorage.getItem("_rr_user"));
    }
    
  },
  methods: {
    async load_my_order() {
      this.is_load_my_order = "Y"
      this.data_my_order = [];
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      const res = await axios.post("/v1/member-event/order", null, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.data_my_order = res.data.body
        this.is_load_my_order = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_load_my_order = "N"
      }
    }
  }
};
</script>
