<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <welcomecard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>กิจกรรมของฉัน</b></h5>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: left">ชื่อกิจกรรม</th>
                    <th style="text-align: left">วันที่จัดกิจกรรม</th>
                    <th style="text-align: left">วันที่เปิดระบสมัคร</th>
                    <th style="text-align: left">วันที่ปิดรับระบสมัคร</th>
                    <th style="text-align: left">รายงาน</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading === 'Y'">
                  <tr>
                    <td colspan="10">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_my_order.length !== 0">
                  <tr v-for="item in this.data_my_order" :key="item">
                    <td style="text-align: left">{{ item.title }}</td>
                    <td style="text-align: left">{{ item.event_date_text }}</td>
                    <td style="text-align: left">{{ item.registration_start_text }}</td>
                    <td style="text-align: left">{{ item.registration_end_text }}</td>
                    <td style="text-align: left"><a :href="'/dashboard-organizer/' + item.event_key">รายงานผู้สมัคร</a></td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import welcomecard from "./user_welcomecard.vue";
import axios from "axios";
export default {
  components: {
    welcomecard,
  },
  data() {
    return {
      token: null,
      is_loading: "N",
      data_my_order: []
    }
  },
  async mounted() {
    this.token = localStorage.getItem("_rr_token");
    if(this.token !== null){
      this.load_my_order();
    }else{
      //alert("เซสชันหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง")
      this.$router.push("/auth/login");
    }
   
  },
  methods: {
    async load_my_order() {
      this.is_loading = "Y"
      this.data_my_order = [];
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      const res = await axios.post("v1/organizer/event", null, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.data_my_order = res.data.body
        this.is_loading = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading = "N"
      }
    }
  }
};
</script>
