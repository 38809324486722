<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-4 d-flex">
            <h6>{{ title }}</h6>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
  },
};
</script>
